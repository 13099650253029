.adm-index-bar {
  --color: var(--adm-color-text);
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: var(--adm-color-background);
  --sticky-offset-top: 0;
}
.adm-index-bar-body {
  overflow: scroll;
  height: 100%;
  width: 100%;
}
.adm-index-bar-body::-webkit-scrollbar {
  display: none;
}
.adm-index-bar-anchor-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 35px;
  padding: 0 12px;
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
  background-color: var(--adm-color-fill-content);
}
.adm-index-bar-sidebar {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 910;
  overflow: visible;
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-4);
  -webkit-user-select: none;
          user-select: none;
  touch-action: none;
}
.adm-index-bar-sidebar-bubble {
  position: absolute;
  top: 50%;
  right: 60px;
  width: 47px;
  height: 47px;
  color: var(--adm-color-text-light-solid);
  font-size: 25px;
  line-height: 47px;
  text-align: center;
  background: var(--adm-color-light);
  border-radius: 50%;
  transform: translateY(-50%);
}
.adm-index-bar-sidebar-row {
  cursor: pointer;
  width: auto;
  text-align: right;
  position: relative;
  padding: 0 12px;
}
.adm-index-bar-sidebar-row > * {
  pointer-events: none;
}
.adm-index-bar-sidebar-item {
  display: inline-flex;
  width: 16px;
  height: 16px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
}
.adm-index-bar-sidebar-item-active {
  color: var(--adm-color-text-light-solid);
  background-color: var(--adm-color-primary);
  border-radius: 50%;
}
.adm-index-bar-sidebar-interacting {
  width: 100%;
}
.adm-index-bar-sticky .adm-index-bar-anchor-title {
  position: -webkit-sticky;
  position: sticky;
  z-index: 900;
  top: var(--sticky-offset-top);
  left: 0;
}
