.adm-search-bar {
  --height: 32px;
  --padding-left: 8px;
  --background: var(--adm-color-fill-content);
  --border-radius: 6px;
  --placeholder-color: var(--adm-color-weak);
  ---placeholder-color: var(--placeholder-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--height);
}
.adm-search-bar .adm-search-bar-input-box {
  flex: auto;
  background: var(--background);
  border-radius: var(--border-radius);
  border: solid 1px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--padding-left);
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon {
  flex: none;
  color: var(--adm-color-light);
  font-size: var(--adm-font-size-8);
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input {
  flex: auto;
  padding: 4px 8px 4px 4px;
  height: calc(var(--height) - 2px);
  box-sizing: border-box;
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
  --placeholder-color: var(---placeholder-color);
  --font-size: var(--adm-font-size-7);
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input .adm-input-element {
  line-height: 19px;
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-search-bar-input-without-icon {
  padding-left: 8px;
}
.adm-search-bar .adm-search-bar-suffix {
  flex: none;
  margin-left: 4px;
}
.adm-search-bar .adm-search-bar-cancel-button.adm-button {
  padding: 3px 12px;
}
.adm-search-bar-active .adm-input.adm-input.adm-input {
  --placeholder-color: var(--adm-color-light);
}
.adm-search-bar-active .adm-search-bar-input-box {
  border-color: var(--adm-color-primary);
  background: var(--adm-color-background);
}
