.adm-swipe-action {
  --background: var(--adm-color-background);
  background: var(--background);
  cursor: -webkit-grab;
  cursor: grab;
  overflow: hidden;
  touch-action: pan-y;
}
.adm-swipe-action-track {
  position: relative;
  overflow: visible;
}
.adm-swipe-action-actions {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: auto;
  white-space: nowrap;
}
.adm-swipe-action-actions-right {
  left: 100%;
  top: 0;
  height: 100%;
}
.adm-swipe-action-actions-left {
  right: 100%;
  top: 0;
  height: 100%;
}
.adm-swipe-action-action-button.adm-button {
  --border-radius: 0;
  --border-width: 0;
  --text-color: var(--adm-color-text-light-solid);
  padding-left: 20px;
  padding-right: 20px;
}
